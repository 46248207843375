<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as moment from "moment";
import Swal from "sweetalert2";
import { required, minValue, maxLength } from "vuelidate/lib/validators"
import Breadcrumb from '../../../components/breadcrumb.vue';
const refValidation = (value) => /^[a-zA-Z0-9_-]+$/.test(value)

import {
  FETCH_SUBSCRIPTION_DETAILS,
  FETCH_SUBSCRIPTION_TRANSACTIONS,
  DELETE_SUBSCRIPTION,
  POST_COLLECT_PAYMENT
} from "@/state/actions.type";

export default {
  components: {
    Layout,
    // PageHeader,
    Spinner,
    Breadcrumb
  },
  data() {
    return {
      pageDetails: null,
      pageTransactions: [],
      pageRef: "",
      isAddPaymentLinkModalOpen: false,
      fields: [
        { key: "created_at", sortable: true, label: "Date" },
        { key: "order_ref", sortable: true, label: "Transaction Order Ref" },
        { key: "status", sortable: true, label: "Status" },
        { key: "amount", sortable: true, label: "Amount" },
        { key: "channel_name", sortable: true,label: "Payment Channel" },
        { key: "method_key", sortable: true, label: "Payment Method" },
      ],
      isFetchingTransactions: false,
      collectPaymentForm: {
        amount: null,
        description: "",
        merchant_order_ref: "",
      }
    };
  },
  validations: {
    collectPaymentForm: {
      amount: {
        required,
        minValue: minValue(1),
        maxLength: maxLength(10),
      },
      description: {},
      merchant_order_ref: {
        required,
        refValidation
      }
    }
  },
  filters: {
      date(value) {
        return moment(value).format("DD MMM YYYY");
      },
      time(value) {
          return 'at ' + moment(value).format("hh:mm A");
      },
  },

  created() {
    this.pageRef = this.$route.query.pageRef;
    this.fetchSubscriptionData();
  },

  computed: {
    // isMasterMerchant() {
    //   return this.$store.state.auth.currentUser.is_master_merchant;
    // },

    items() {
      return [
        {
          text: this.$t('menuitems.subscriptions.text'),
          to: { name: 'Subscription', query: {tab: 'Subscription'} }
        },
        {
          text: 'View Details',
          active: true,
        },
      ];
    },

    collection() {
      let amount = this.formatAmount(this.pageDetails.collected_amount);
      let count = this.pageDetails.collected_count;
      if(this.pageDetails.subscription_type === 'REGULAR') {
        amount += `/${this.pageDetails.total_amount}`;
        count += `/${this.pageDetails.recurrance_count}`;
      }
      return `${amount} - ${count}`;
    }
  },

  methods: {
    formatAmount(amount, currency) {
      let choosenLanguage = this.$store.state.auth.language || 'en';
      let formattedValue = new Intl.NumberFormat(choosenLanguage, {
        style: "currency",
        currency: currency ? currency : this.pageDetails.currency,
        minimumFractionDigits: 2,
      }).format(amount);
      return formattedValue;
    },
    dateFormat(date) {
      return moment(date).format("Do MMMM YYYY hh:mm a");
    },
    fetchSubscriptionData() {
        this.$store.dispatch(`subscription/${FETCH_SUBSCRIPTION_DETAILS}`, { order_ref: this.pageRef, key: this.$route.query.key })
        .then((response) => {
          this.pageDetails = response.content;
          if(this.pageDetails.status === 'Active' || this.pageDetails.status === 'Authenticated') {
            this.pageDetails.next_deduction_amount = this.pageDetails.subscription_type === 'ONDEMAND' ? 'As per Request' : this.formatAmount(this.pageDetails.next_deduction_amount);
            this.pageDetails.next_deduction_date = this.pageDetails.subscription_type === 'ONDEMAND' ? 'As per Request' : this.dateFormat(this.pageDetails.next_deduction_date);
          }
          this.pageDetails.started_at = this.dateFormat(this.pageDetails.started_at);
          this.pageDetails.expired_at = this.dateFormat(this.pageDetails.expired_at);
          this.setTransactionData();
        });
    },

    setTransactionData() {
      this.isFetchingTransactions = true;
      this.$store.dispatch(`subscription/${FETCH_SUBSCRIPTION_TRANSACTIONS}`, { order_ref: this.pageRef, key: this.$route.query.key })
      .then((response) => {
        this.pageTransactions = response.content;
      })
      .finally(() => this.isFetchingTransactions = false);
    },

    deleteSubscription(order_ref) {

      Swal.fire({
        title: "Are you sure you want to delete?",
        text:"Order Ref - "+ order_ref,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
        showClass: {
          backdrop: 'swal2-noanimation', // disable backdrop animation
          popup: '',                     // disable popup animation
          icon: ''                       // disable icon animation
        },
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch(`subscription/${DELETE_SUBSCRIPTION}`, order_ref)
            .then((response) => {
              console.log(response);

              Swal.fire("Deleted!", "Subscription has been deleted.", "success");

              setTimeout(() => {
                this.fetchSubscriptionData();
              }, 500);
            })
            .catch((error) => {
              console.log("error", error);
              Swal.fire("Deleted!", "Failed To delete Subscription.", "error");
            });
        }
      });
    },
    resetCollectPayment() {
      this.collectPaymentForm = {
        amount: null,
        description: "",
        merchant_order_ref: `${(this._uid + this.$uuid.v1()).replace(/-/g, "").substring(1, 13).toUpperCase()}${this._uid}`
      }
      this.$v.collectPaymentForm.$reset();
    },
    handleCollectPayment(bvModalEvent) {
      bvModalEvent.preventDefault()

      this.$v.collectPaymentForm.$touch();

      if (this.$v.collectPaymentForm.$invalid) {
        return;
      }

      const payload = {
        "subscription_order_ref": this.pageRef,
        "merchant_order_ref": this.collectPaymentForm.merchant_order_ref,
        "amount": this.collectPaymentForm.amount,
        "description": this.collectPaymentForm.description,
        "currency": this.pageDetails.currency,
        "deduction_date": "",
      }
      this.$store.dispatch(`subscription/${POST_COLLECT_PAYMENT}`, payload)
      .catch(error => {
        const err = JSON.parse(error.message);
        this.$notify({
          'type': 'error',
          'text': err.message
        })
      })
      .finally(() => this.fetchSubscriptionData());

      this.$nextTick(() => {
        this.$bvModal.hide('collect-payment-modal')
      });
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: "Subscription Link copied to clipboard.",
        closeOnClick: true,
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.collectPaymentForm[name];
      return $dirty ? !$error : null;
    },
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <breadcrumb :items="items" :goBackTo="{ name: 'Subscription', query: {tab: 'Subscription'} }" />
    <div class="row" v-if="pageDetails">
      <div class="col-lg-12">
        <div>
          <div class="row">
            <div class="col-sm-12 col-md-12">
              <div class="row mb-4">
                <div class="col-md-12 d-flex justify-content-end">
                  <span class="btn dropdown-toggle btn-link">
                    <button v-if="pageDetails.subscription_type === 'ONDEMAND' && pageDetails.status == 'Active'" class="btn custom-btn mr-2"  v-b-modal.collect-payment-modal>
                      Collect Payment
                    </button>
                  </span>
                </div>
              </div>

              <b-modal
                id="collect-payment-modal"
                title="Collect Payment"
                ok-title="Save"
                cancel-variant="light"
                no-close-on-backdrop
                @show="resetCollectPayment"
                @hidden="resetCollectPayment"
                @ok="handleCollectPayment"
              >
                <form ref="form">
                  <b-form-group
                    label="Amount"
                    label-for="amount-input"
                    invalid-feedback="Amount is required"
                    :state="validateState('amount')"
                  >
                    <b-form-input
                      id="amount-input"
                      v-model.number="collectPaymentForm.amount"
                      :state="validateState('amount')"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    :label="$t('subscription.description')"
                    label-for="description-input"
                    invalid-feedback="Description is required"
                    :state="validateState('description')"
                  >
                    <textarea
                      v-model.trim="collectPaymentForm.description"
                      type="text"
                      class="form-control"
                      placeholder="Product name for this purchase"
                      name="description"
                      :maxlength="200"
                    />
                    <div style="margin-top: 5px;">
                      <span style="color: #FC6B2D;font-size: 14px;">Note :</span><span style="opacity: 0.50;color: black;font-size: 12px;padding-left: 10px;">Maximum 200 characters are allowed.</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label="Merchant Order Ref"
                    label-for="merchant-order-ref-input"
                    invalid-feedback="Merchant order ref is required and must contain alpha numeric chars only"
                    :state="validateState('merchant_order_ref')"
                  >
                    <b-form-input
                      id="merchant-order-ref-input"
                      v-model="collectPaymentForm.merchant_order_ref"
                      :state="validateState('merchant_order_ref')"
                      required
                    ></b-form-input>
                  </b-form-group>
                </form>
              </b-modal>
              <div class="top-section">
                <div class="top-section-header">
                  <span style="font-size: 20px;color: #000000;">Detailed Subscription View</span>
                </div>

              </div>

              <div class="mb-4 row rounded" v-if="pageDetails">
                  <div class="col-md-12">
                    <table class="page-details-1" width="100%">
                      <colgroup>
                        <col width="50%">
                        <col width="50%">
                      </colgroup>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Plan Name</td>
                        <td>
                          <span class="text-chai">{{ pageDetails.plan_name }}</span>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Plan Order Ref</td>
                        <td>
                            {{ pageDetails.price_order_ref }}
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Subscription Created On</td>
                        <td>
                            {{ dateFormat(pageDetails.created_at) }}
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Subscription Link</td>
                        <td>
                          <div class="d-flex justify-content-start">
                            <p class="mb-0 my-auto">
                              <a :href="pageDetails.link"  class="text-chai" target="_blank">{{ pageDetails.link }}</a></p>
                            <span class="ml-2" 
                                v-show="pageDetails.link"
                                v-clipboard:copy="pageDetails.link"
                                v-clipboard:success="onCopySuccess"
                                v-b-tooltip.hover.right
                                :title="$t('common.copy')"
                                style="cursor: pointer; width: 40px;height: 40px;background: rgba(245, 244, 243, 1);border-radius: 8px;position: relative;">
                              <svg style="position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g opacity="0.7" clip-path="url(#clip0_1540_47398)">
                                  <path d="M12 9.675V12.825C12 15.45 10.95 16.5 8.325 16.5H5.175C2.55 16.5 1.5 15.45 1.5 12.825V9.675C1.5 7.05 2.55 6 5.175 6H8.325C10.95 6 12 7.05 12 9.675Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M16.5 5.175V8.325C16.5 10.95 15.45 12 12.825 12H12V9.675C12 7.05 10.95 6 8.325 6H6V5.175C6 2.55 7.05 1.5 9.675 1.5H12.825C15.45 1.5 16.5 2.55 16.5 5.175Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1540_47398">
                                    <rect width="18" height="18" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </div>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Status</td>
                        <td>
                          <b-badge
                             style="padding: 9px 15px;border-radius: 8px;font-size: 12px;line-height: 20px;"
                          :class="{
                            'badge-soft-warning': `${pageDetails.status}` === 'Created' || `${pageDetails.status}` === 'Pending',
                            'badge-soft-authenticated': `${pageDetails.status}` === 'Authenticated',
                            'badge-soft-success': `${pageDetails.status}` === 'Active' || `${pageDetails.status}` === 'Completed',
                            'badge-soft-danger': `${pageDetails.status}` === 'Cancelled' || `${pageDetails.status}` === 'Expired',
                          }">{{ pageDetails.status }}</b-badge>
                           <span style="text-decoration: underline;padding-left: 25px; cursor: pointer; color: #FC6B2D;" v-if="pageDetails.status == 'Created' || pageDetails.status == 'Active'" class="" @click="deleteSubscription(pageRef)">Cancel Subscription </span>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{this.pageDetails.subscription_type === 'ONDEMAND' ? 'Total Amount' : 'Plan Amount'}}</td>
                        <td>{{ formatAmount(this.pageDetails.subscription_type === 'ONDEMAND' ? pageDetails.initial_amount : pageDetails.price_amount) }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;" v-if="pageDetails.status === 'Cancelled'">
                        <td>Status Channel Reason</td>
                        <td>
                          {{ pageDetails.status_channel_reason }}
                        </td>
                      </tr>
                    <tr :key="index" v-for="(option, index) in pageDetails.additional_costs" :data-key="index" style="border-bottom: 1px #DCDAD5 solid;" >
                        <td>{{ option.key }} </td>
                        <td>{{ option.value }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>
                          Collection
                          <img
                            src="@/assets/images/tooltip_icon.svg"
                            style="position: relative; bottom: 2px; cursor: pointer;"
                            alt=""
                            :title="pageDetails.subscription_type !== 'ONDEMAND' ? 'Collected Amount/Total Amount - Collected Count/Total Count' : 'Collected Amount - Collected Count'"
                            v-b-tooltip.hover
                          />
                        </td>
                        <td>{{ collection }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Link Expiry</td>
                        <td>{{ pageDetails.expired_at }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Merchant Order Ref</td>
                        <td>
                            {{ pageDetails.merchant_order_ref }}
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;"  v-if="pageDetails.status === 'Active' || pageDetails.status === 'Authenticated'">
                        <td>Next Deduction Amount</td>
                        <td>{{ pageDetails.next_deduction_amount }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;" v-if="pageDetails.status === 'Active' || pageDetails.status === 'Authenticated'">
                        <td>Next Deduction Date</td>
                        <td>{{ pageDetails.next_deduction_date }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Started At</td>
                        <td>{{ pageDetails.started_at }}</td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>Customer Name</td>
                        <td>{{ pageDetails.customer_name }}</td>
                      </tr>

                      <tr v-if="pageDetails.customer_phone_number || pageDetails.customer_email_address" style="border-bottom: 1px #DCDAD5 solid;">
                        <td> Contact Details </td>
                        <td>
                         <span v-if="pageDetails.customer_phone_number || pageDetails.customer_email_address"> 
                            ( <span style="color: #FC6B2D;" >
                              {{ pageDetails.customer_phone_number }}
                              <span v-if="pageDetails.customer_phone_number && pageDetails.customer_email_address">,</span> 
                              {{ pageDetails.customer_email_address }}</span> )
                          </span>
                        </td>
                      </tr>
                      <tr style="border-bottom: 1px #DCDAD5 solid;">
                        <td>{{ $t('views.payment_links.table.fields.send_link') }}</td>
                        <td>
                          <div class="" id="single-merchant-cont" style="width:75px; cursor: default; display: inline-block;">
                                <div class="checkbox-group-custom">
                                  <input type="radio" id="single_entity" value="false">
                                  <label for="single_entity" class="position-relative mb-0"
                                  :class="{
                                      'filled': pageDetails.notify_by_email
                                    }">{{ $t('views.payment_links.table.fields.email') }}</label>
                                </div>
                              </div>
                              <div class="" id="master-merchant-cont" style="border-color: #DAD9D9; cursor: default; display: inline-block; margin-left: 40px;">
                                <div class="checkbox-group-custom">
                                  <input type="radio" id="master_entity" value="true">
                                  <label for="master_entity" class="position-relative mb-0"
                                  :class="{
                                      'filled': pageDetails.notify_by_phone
                                    }">{{ $t('views.payment_links.table.fields.sms') }}</label>
                                </div>
                              </div>
                        </td>
                      </tr>
                      <tr :key="index" v-for="(option, index) in pageDetails.notes" :data-key="index" style="border-bottom: 1px #DCDAD5 solid;" >
                        <td>{{ option.key }} </td>
                        <td>{{ option.value }}</td>
                      </tr>
                    </table>
                  </div>
              </div>
            </div>
          </div>
            <b-table
              class="table-custom text-left table-centered"
              :items="pageTransactions"
              :fields="fields"
              responsive="sm"
              :small="true"
              sticky-header="500px"
              show-empty
              :empty-text="$t('common.no-records')"
              busy.sync="true"
              hover
            >
              <template v-slot:cell(created_at)="{ value }">
                  <p class="table-date">{{ value | date }}</p>
                  <p class="table-time">{{ value | time }}</p>
              </template>
              <template v-slot:cell(order_ref)="row">
                <router-link
                  v-if="row.value"
                  style="color: #FC6B2D;"
                  :to="`/transactions/payments/payment-details?order_ref=${row.value}&key=${$route.query.key}`"
                >
                  {{row.value}}
                </router-link>
              </template>
              <template v-slot:cell(amount)="row">
                {{ formatAmount(row.value, row.item.currency) }}
              </template>
              <template v-slot:cell(status)="row">
                <div
                  class="badge font-size-12"
                  :class="{'badge-soft-danger': `${row.value}` === 'Failed' || `${row.value}` === 'Expired',
                      'badge-soft-success': `${row.value}` === 'Success',
                      'badge-soft-warning': `${row.value}` === 'Initiated'}"
                >{{ row.value}}</div>
              </template>
            </b-table>
            <Spinner v-if="isFetchingTransactions === true"></Spinner>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .main-content {
    overflow: visible;
}
.page-details-1 td:first-child{
  height: 60px;
  color: #00000080;
  font-size: 15px;
  padding-left: 25px;
}
.page-details-1 td:last-child{
  font-size: 15px;
  color: #060606;
  position: relative;
  left: 15px;
}
.custom-btn {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  opacity: 1;
  color: #000000;
}
.checkbox-group-custom{
  position: relative;
}
.checkbox-group-custom input{
  display: none;
}
.checkbox-group-custom  label {
  cursor: pointer;
}
.checkbox-group-custom{
  display: inline-block;
}
.checkbox-group-custom  label {
  cursor: default;;
}
.checkbox-group-custom  label:before {
    content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #fc6b2d;
  border-radius: 6px;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group-custom  label.filled:before {
  background-color: rgba(252,107,45,.1);
  border: 1px solid #fc6b2d;
}

.checkbox-group-custom label.filled:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 11px;
  border: 1px solid #fc6b2d;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
::v-deep .custom-control {
  z-index: unset;
}
.top-section-header{
  background: #dcdad54d;
  padding: 15px 25px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom: 1px #DCDAD5 solid;
}
.badge-soft-success{
    background: rgba(33, 150, 83, 0.10);
    color: #219653;
}
.badge-soft-danger{
    background: rgba(222, 53, 53, 0.10);
    color: #DE3535;
}
</style>